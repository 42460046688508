document.addEventListener("DOMContentLoaded", () => {
  const jpButton = document.getElementById("lang_jp");
  const enButton = document.getElementById("lang_en");

  if (jpButton && enButton) {
    jpButton.addEventListener("click", () => switchLanguage("jp"));
    enButton.addEventListener("click", () => switchLanguage("en"));

    setActiveButton();
  }
});

const getDomain = (url: string): string => {
  if (url.includes("localhost")) return "localhost:3000";
  if (url.includes("enters-lab")) return "contrail.enters-lab.co.jp";
  if (url.includes("contrail.aero")) return "contrail.aero";
  return "";
};

const setActiveButton = () => {
  const path = window.location.pathname;
  const isEnPage =
    path.startsWith("/en") ||
    path.startsWith("/en_news") ||
    path.startsWith("/en_category");

  toggleActiveButton(isEnPage);
};

const toggleActiveButton = (isEnPage: boolean) => {
  const jpButton = document.getElementById("lang_jp");
  const enButton = document.getElementById("lang_en");

  if (!jpButton || !enButton) return;
  if (isEnPage) {
    enButton.classList.add("active");
    jpButton.classList.remove("active");
  } else {
    enButton.classList.remove("active");
    jpButton.classList.add("active");
  }
};

const switchLanguage = (lang: "jp" | "en") => {
  const currentUrl = window.location.href;
  const domain = getDomain(currentUrl);
  const path = window.location.pathname;

  let newUrl = currentUrl;

  if (lang === "jp") {
    newUrl = switchToJapanese(domain, path);
  } else if (lang === "en") {
    newUrl = switchToEnglish(domain, path);
  }

  if (newUrl !== currentUrl) {
    window.location.href = newUrl;
  }
};

const switchToJapanese = (domain: string, path: string): string => {
  if (path.startsWith("/en_news")) {
    return `https://${domain}/news/`;
  } else if (path.startsWith("/en_category")) {
    return `https://${domain}${path.replace("/en_category", "/category")}`;
  } else if (path.startsWith("/en")) {
    return `https://${domain}${path.replace("/en", "")}`;
  }
  return `https://${domain}${path}`;
};

const switchToEnglish = (domain: string, path: string): string => {
  if (path.startsWith("/en")) {
    return `https://${domain}${path}`;
  } else if (path.startsWith("/news")) {
    return `https://${domain}/en/news/`;
  } else if (path.startsWith("/category")) {
    return `https://${domain}${path.replace("/category", "/en_category")}`;
  } else {
    return `https://${domain}/en${path}`;
  }
};
