import $ from "jquery";

$(".accordion_button").click(function() {
  $(this).next(".accordion_body").slideToggle("fast");
  $(this).toggleClass("active");
  if ($(this).hasClass("active")) {
    $(this).children("span.mark").text("−");
  } else {
    $(this).children("span.mark").text("＋");
  }
});
