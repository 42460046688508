import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from "swiper";

// init Swiper:
const heroSwiper = new Swiper(".hero_swiper.swiper", {
  modules: [Autoplay, EffectFade],
  loop: true,
  speed: 3000,
  centeredSlides: true,
  effect: "fade",
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  slidesPerView: 1,
  spaceBetween: 0,
});

const homeSwiper = new Swiper(".home_swiper.swiper", {
  modules: [Navigation, Autoplay],
  loop: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  speed: 300,
  pagination: {
    el: ".swiper-pagination",
  },
  slidesPerView: 1,
  spaceBetween: 10,
  breakpoints: {
    768: {
      slidesPerView: 2,
      slidesPerGroup: 2,
    },
  },
});
