const replaceStrings = (selector: string, text: string): void => {
  const url = new URL(window.location.href);
  const paths = url.pathname.split("/");
  const isEnglishSite = paths[1] === "en";

  const element = document.querySelector(selector);

  if (element instanceof HTMLElement) {
    if (isEnglishSite) {
      console.log("text", text);
      element.textContent = text;
    }
  }
};

setTimeout(() => {
  replaceStrings(".cd-upload-btn", "Upload");
}, 3000);
