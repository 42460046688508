const navigation = document.querySelector("#navigation") as HTMLElement | null;
const hero = document.querySelector("#hero") as HTMLElement | null;
const hero_child = document.querySelector("#hero_child") as HTMLElement | null;
const navigationHeight = navigation ? navigation.clientHeight : 0;

if (hero && navigation) {
  let observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          navigation.classList.add("active");
        } else {
          navigation.classList.remove("active");
        }
      });
    },
    {
      root: null,
      rootMargin: `-${navigationHeight + 50}px`,
      threshold: 0,
    }
  );

  if (hero) observer.observe(hero);
  if (hero_child) observer.observe(hero_child);
}

window.addEventListener("load", () => {
  adjustScrollPositionOnLoad();

  window.addEventListener("hashchange", adjustScrollPosition, false);
});

function adjustScrollPositionOnLoad(): void {
  if (window.location.hash) {
    setTimeout(() => {
      adjustScrollPosition();
    }, 10);
  }
}

function adjustScrollPosition(): void {
  const header: HTMLElement | null = document.querySelector("#navigation");
  const hash: string = window.location.hash;
  const targetElement: HTMLElement | null = document.querySelector(hash);

  if (header && targetElement) {
    const headerHeight: number = header.offsetHeight;
    window.scrollTo({
      top: targetElement.offsetTop - headerHeight,
      behavior: "smooth",
    });
  }
}
