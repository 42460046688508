const hamburger = document.querySelector("#hamburger") as HTMLDivElement | null;
const hamburger_close = document.querySelector(
  "#hamburger_close"
) as HTMLDivElement | null;
const drawer = document.querySelector(".drawer") as HTMLDivElement | null;

if (hamburger && drawer && hamburger_close) {
  hamburger.addEventListener("click", () => {
    drawer.classList.add("active");
  });
  hamburger_close.addEventListener("click", () => {
    drawer.classList.remove("active");
  });
}
