document.addEventListener("DOMContentLoaded", () => {
  const homeRecruitLabels = document.querySelectorAll(".home .recruit .label");
  if (homeRecruitLabels) {
    for (const homeRecruitLabel of homeRecruitLabels) {
      homeRecruitLabel.addEventListener("mouseenter", () => {
        homeRecruitLabel.classList.add("hover");
      });
      homeRecruitLabel.addEventListener("mouseleave", () => {
        homeRecruitLabel.classList.remove("hover");
      });
    }
  }

  const homeServiceCards = document.querySelectorAll(".home .service .card");
  for (const homeServiceCard of homeServiceCards) {
    if (homeServiceCard) {
      homeServiceCard.addEventListener("mouseenter", () => {
        homeServiceCard.classList.add("hover");
      });
      homeServiceCard.addEventListener("mouseleave", () => {
        homeServiceCard.classList.remove("hover");
      });
    }
  }

  const navigation = document.querySelector("#navigation");
  const visionAnchorOne = document.querySelector(
    ".page-vision .anchor .item01"
  );
  const visionAnchorTwo = document.querySelector(
    ".page-vision .anchor .item02"
  );
  const visionAnchorThree = document.querySelector(
    ".page-vision .anchor .item03"
  );
  const visionItemOne = document.querySelector(
    ".page-vision .main_content .item01"
  );
  const visionItemTwo = document.querySelector(
    ".page-vision .main_content .item02"
  );
  const visionItemThree = document.querySelector(
    ".page-vision .main_content .item03"
  );

  if (
    navigation &&
    visionAnchorOne &&
    visionAnchorTwo &&
    visionAnchorThree &&
    visionItemOne &&
    visionItemTwo &&
    visionItemThree
  ) {
    visionAnchorOne.addEventListener("click", () => {
      const visionIteOneHeight = visionItemOne.getBoundingClientRect().top;
      const offsetPosition = visionIteOneHeight + window.pageYOffset - 100;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    });
    visionAnchorTwo.addEventListener("click", () => {
      const visionItemTwoHeight = visionItemTwo.getBoundingClientRect().top;
      const offsetPositionTwo = visionItemTwoHeight + window.pageYOffset - 100;
      window.scrollTo({
        top: offsetPositionTwo,
        behavior: "smooth",
      });
    });

    visionAnchorThree.addEventListener("click", () => {
      const visionItemThreeHeight = visionItemThree.getBoundingClientRect().top;
      const offsetPositionThree =
        visionItemThreeHeight + window.pageYOffset - 100;
      window.scrollTo({
        top: offsetPositionThree,
        behavior: "smooth",
      });
    });
  }

  const recruitCultureAnchor01 = document.querySelector(
    ".page-recruit-culture .anchor .item01"
  );
  const recruitCultureAnchor02 = document.querySelector(
    ".page-recruit-culture .anchor .item02"
  );
  const recruitCultureAnchor03 = document.querySelector(
    ".page-recruit-culture .anchor .item03"
  );
  const recruitCultureAnchor04 = document.querySelector(
    ".page-recruit-culture .anchor .item04"
  );
  const recruitCultureItem01 = document.querySelector(
    ".page-recruit-culture .main_content .item01"
  );
  const recruitCultureItem02 = document.querySelector(
    ".page-recruit-culture .main_content .item02"
  );
  const recruitCultureItem03 = document.querySelector(
    ".page-recruit-culture .main_content .item03"
  );
  const recruitCultureItem04 = document.querySelector(
    ".page-recruit-culture .main_content .item04"
  );

  if (
    navigation &&
    recruitCultureAnchor01 &&
    recruitCultureAnchor02 &&
    recruitCultureAnchor03 &&
    recruitCultureAnchor04 &&
    recruitCultureItem01 &&
    recruitCultureItem02 &&
    recruitCultureItem03 &&
    recruitCultureItem04
  ) {
    recruitCultureAnchor01.addEventListener("click", () => {
      const recruitCultureItem01Height =
        recruitCultureItem01.getBoundingClientRect().top;
      const offsetPosition =
        recruitCultureItem01Height + window.pageYOffset - 100;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    });
    recruitCultureAnchor02.addEventListener("click", () => {
      const recruitCultureItem02Height =
        recruitCultureItem02.getBoundingClientRect().top;
      const offsetPositionTwo =
        recruitCultureItem02Height + window.pageYOffset - 100;
      window.scrollTo({
        top: offsetPositionTwo,
        behavior: "smooth",
      });
    });

    recruitCultureAnchor03.addEventListener("click", () => {
      const recruitCultureItem03Height =
        recruitCultureItem03.getBoundingClientRect().top;
      const offsetPositionThree =
        recruitCultureItem03Height + window.pageYOffset - 100;
      window.scrollTo({
        top: offsetPositionThree,
        behavior: "smooth",
      });
    });

    recruitCultureAnchor04.addEventListener("click", () => {
      const recruitCultureItem04Height =
        recruitCultureItem04.getBoundingClientRect().top;
      const offsetPositionFour =
        recruitCultureItem04Height + window.pageYOffset - 100;
      window.scrollTo({
        top: offsetPositionFour,
        behavior: "smooth",
      });
    });
  }

  const serviceTopButton01 = document.querySelector(
    ".page-service .service .list .item .link01"
  );
  const serviceTopButton02 = document.querySelector(
    ".page-service .service .list .item .link02"
  );
  const serviceTopButton03 = document.querySelector(
    ".page-service .service .list .item .link03"
  );
  const serviceTopImage01 = document.querySelector(
    ".page-service .service .list .item .image01"
  );
  const serviceTopImage02 = document.querySelector(
    ".page-service .service .list .item .image02"
  );
  const serviceTopImage03 = document.querySelector(
    ".page-service .service .list .item .image03"
  );

  if (
    serviceTopButton01 &&
    serviceTopButton02 &&
    serviceTopButton03 &&
    serviceTopImage01 &&
    serviceTopImage02 &&
    serviceTopImage03
  ) {
    serviceTopButton01.addEventListener("mouseenter", () => {
      serviceTopImage01.classList.add("hover");
    });
    serviceTopButton01.addEventListener("mouseleave", () => {
      serviceTopImage01.classList.remove("hover");
    });

    serviceTopButton02.addEventListener("mouseenter", () => {
      serviceTopImage02.classList.add("hover");
    });
    serviceTopButton02.addEventListener("mouseleave", () => {
      serviceTopImage02.classList.remove("hover");
    });

    serviceTopButton03.addEventListener("mouseenter", () => {
      serviceTopImage03.classList.add("hover");
    });
    serviceTopButton03.addEventListener("mouseleave", () => {
      serviceTopImage03.classList.remove("hover");
    });
  }

  const recruitInterviewButton01 = document.querySelector(
    ".page-recruit-interview .header .item01"
  );
  const recruitInterviewButton02 = document.querySelector(
    ".page-recruit-interview .header .item02"
  );
  if (recruitInterviewButton01 && recruitInterviewButton02) {
    recruitInterviewButton01.addEventListener("mouseenter", () => {
      recruitInterviewButton01.classList.add("hover");
    });
    recruitInterviewButton01.addEventListener("mouseleave", () => {
      recruitInterviewButton01.classList.remove("hover");
    });

    recruitInterviewButton02.addEventListener("mouseenter", () => {
      recruitInterviewButton02.classList.add("hover");
    });

    recruitInterviewButton02.addEventListener("mouseleave", () => {
      recruitInterviewButton02.classList.remove("hover");
    });
  }

  const recruitRequirementsButton01 = document.querySelector(
    ".page-recruit-requirements .side .item.item01"
  );
  const recruitRequirementsButton02 = document.querySelector(
    ".page-recruit-requirements .side .item.item02"
  );
  const recruitRequirementsButton03 = document.querySelector(
    ".page-recruit-requirements .side .item.item03"
  );

  const recruitRequirementsHeaderButtin01 = document.querySelector(
    ".page-recruit-requirements .intro .button01"
  );
  const recruitRequirementsHeaderButtin02 = document.querySelector(
    ".page-recruit-requirements .intro .button02"
  );
  const recruitRequirementsHeaderButtin03 = document.querySelector(
    ".page-recruit-requirements .intro .button03"
  );

  const recruitRequirementsItem01 = document.querySelector(
    ".page-recruit-requirements .main .require_wrapper.require_wrapper01"
  );
  const recruitRequirementsItem02 = document.querySelector(
    ".page-recruit-requirements .main .require_wrapper.require_wrapper02"
  );
  const recruitRequirementsItem03 = document.querySelector(
    ".page-recruit-requirements .main .require_wrapper.require_wrapper03"
  );

  if (
    recruitRequirementsButton01 &&
    recruitRequirementsButton02 &&
    recruitRequirementsButton03 &&
    recruitRequirementsItem01 &&
    recruitRequirementsItem02 &&
    recruitRequirementsItem03 &&
    recruitRequirementsHeaderButtin01 &&
    recruitRequirementsHeaderButtin02 &&
    recruitRequirementsHeaderButtin03
  ) {
    recruitRequirementsButton01.addEventListener("click", () => {
      const requirements01 =
        recruitRequirementsItem01.getBoundingClientRect().top;
      const offsetPositionOne = requirements01 + window.pageYOffset - 100;
      window.scrollTo({
        top: offsetPositionOne,
        behavior: "smooth",
      });
    });
    recruitRequirementsHeaderButtin01.addEventListener("click", () => {
      const requirements01 =
        recruitRequirementsItem01.getBoundingClientRect().top;
      const offsetPositionOne = requirements01 + window.pageYOffset - 100;
      window.scrollTo({
        top: offsetPositionOne,
        behavior: "smooth",
      });
    });

    recruitRequirementsButton02.addEventListener("click", () => {
      const requirements02 =
        recruitRequirementsItem02.getBoundingClientRect().top;
      const offsetPositionTwo = requirements02 + window.pageYOffset - 100;
      window.scrollTo({
        top: offsetPositionTwo,
        behavior: "smooth",
      });
    });
    recruitRequirementsHeaderButtin02.addEventListener("click", () => {
      const requirements02 =
        recruitRequirementsItem02.getBoundingClientRect().top;
      const offsetPositionTwo = requirements02 + window.pageYOffset - 100;
      window.scrollTo({
        top: offsetPositionTwo,
        behavior: "smooth",
      });
    });

    recruitRequirementsButton03.addEventListener("click", () => {
      const requirements03 =
        recruitRequirementsItem03.getBoundingClientRect().top;
      const offsetPositionThree = requirements03 + window.pageYOffset - 100;
      window.scrollTo({
        top: offsetPositionThree,
        behavior: "smooth",
      });
    });

    recruitRequirementsHeaderButtin03.addEventListener("click", () => {
      const requirements03 =
        recruitRequirementsItem03.getBoundingClientRect().top;
      const offsetPositionThree = requirements03 + window.pageYOffset - 100;
      window.scrollTo({
        top: offsetPositionThree,
        behavior: "smooth",
      });
    });
  }

  const heroChild = document.getElementById("hero_child");
  const visionAnchor = document.querySelector(".page-vision .anchor");
  const recruitCultureAnchor = document.querySelector(
    ".page-recruit-culture .anchor"
  );

  if (heroChild && (visionAnchor || recruitCultureAnchor)) {
    const checkScroll = () => {
      const heroBottom = heroChild.offsetTop + heroChild.offsetHeight;

      if (window.pageYOffset > heroBottom) {
        if (visionAnchor) {
          visionAnchor.classList.remove("hidden");
        }
        if (recruitCultureAnchor) {
          recruitCultureAnchor.classList.remove("hidden");
        }
      } else {
        if (visionAnchor) {
          visionAnchor.classList.add("hidden");
        }
        if (recruitCultureAnchor) {
          recruitCultureAnchor.classList.add("hidden");
        }
      }
    };

    checkScroll();

    window.addEventListener("scroll", checkScroll);
  }

  const recruitInterviewItem01 = document.querySelector(
    ".page-recruit-interview .interview .interview_wrapper.wrapper01"
  );
  const recruitInterviewItem02 = document.querySelector(
    ".page-recruit-interview .interview .interview_wrapper.wrapper02"
  );

  if (
    recruitInterviewItem01 &&
    recruitInterviewItem02 &&
    recruitInterviewButton01 &&
    recruitInterviewButton02
  ) {
    recruitInterviewButton01.addEventListener("click", () => {
      const interview01 = recruitInterviewItem01.getBoundingClientRect().top;
      const offsetPositionOne = interview01 + window.pageYOffset - 100;
      window.scrollTo({
        top: offsetPositionOne,
        behavior: "smooth",
      });
    });

    recruitInterviewButton02.addEventListener("click", () => {
      const interview02 = recruitInterviewItem02.getBoundingClientRect().top;
      const offsetPositionTwo = interview02 + window.pageYOffset - 100;
      window.scrollTo({
        top: offsetPositionTwo,
        behavior: "smooth",
      });
    });
  }
});
