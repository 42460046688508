import "./scripts/swipers";
import "./scripts/drawer";
import "./scripts/navigation";
import "./scripts/hero";
import "./scripts/accordion";
import "./scripts/animationOnScroll";
import "./scripts/animation";
import "./scripts/switchLanguage";
import "./scripts/form";
import "./scripts/replaceStrings";
