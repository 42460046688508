export function setupScrollAnimation(className = "fade_up", threshold = 1) {
  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            entry.target.classList.add("is_visible");
            observer.unobserve(entry.target);
          }, 400);
        }
      });
    },
    {
      rootMargin: "-100px",
      threshold: threshold,
    }
  );

  document.querySelectorAll(`.${className}`).forEach((element) => {
    observer.observe(element);
  });
}

document.addEventListener("DOMContentLoaded", () => {
  setupScrollAnimation("fade_up", 0.1);
  setupScrollAnimation("fade_in", 0.1);
});

const scrollTop = document.querySelector("#scroll_top");
if (scrollTop) {
  scrollTop.addEventListener("click", () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });
}
