document.addEventListener("DOMContentLoaded", function() {
  // URLパラメータを取得する関数
  function getUrlParameter(name: string) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  // "option"パラメータの値を取得
  const option = getUrlParameter('job');

  // 該当するチェックボックスにチェックを入れる
  if (option) {
    const checkboxName = 'job[]'; // Contact Form 7で設定したチェックボックスのname属性
    const checkboxes = document.getElementsByName(checkboxName) as NodeListOf<HTMLInputElement>;

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].value === option) {
        checkboxes[i].checked = true;
      }
    }
  }
});
